import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { marker } from 'images'
import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../layouts'

class AccountantsPage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { edges } = this.props.data.allContentfulPartners

        return (
            <Layout location={this.props.location}>
                <main className="home accountants-overview animated fadeInPage">
                    <Helmet>
                        <title>Vind je accountant| Employes</title>
                        <meta
                            name="description"
                            content="Op zoek naar een goede HR adviseur of accountant? Dat kan een hele klus zijn! Wij hebben ze voor je op een rij gezet."
                        />
                        <meta
                            itemprop="name"
                            content="Vind je HR of financieel adviseur | Employes"
                        />
                        <meta
                            itemprop="description"
                            content="Op zoek naar een goede HR adviseur of accountant? Dat kan een hele klus zijn! Wij hebben ze voor je op een rij gezet."
                        />
                        <meta
                            itemprop="image"
                            content="/static/meta-boekhouder.jpg"
                        />

                        {/* Windows tags */}
                        <meta
                            name="msapplication-TileColor"
                            content="#ffffff"
                        />
                        <meta
                            name="msapplication-TileImage"
                            content="static/favicon/mstile-144x144.png"
                        />

                        {/* OpenGraph tags */}
                        <meta property="og:url" content="" />
                        <meta property="og:type" content="website" />
                        <meta
                            property="og:title"
                            content="Vind je HR of financieel adviseur | Employes"
                        />
                        <meta
                            property="og:description"
                            content="Op zoek naar een goede HR adviseur of accountant? Dat kan een hele klus zijn! Wij hebben ze voor je op een rij gezet."
                        />
                        <meta
                            property="og:image"
                            content="https://employes.nl/static/meta-boekhouder.jpg"
                        />

                        {/* Twitter Card tags */}
                        <meta
                            name="twitter:card"
                            content="summary_large_image"
                        />
                        <meta
                            name="twitter:title"
                            content="Vind je HR of financieel adviseur | Employes"
                        />
                        <meta
                            name="twitter:description"
                            content="Op zoek naar een goede HR adviseur of accountant? Dat kan een hele klus zijn! Maar wij hebben ze voor je op een rij gezet."
                        />
                        <meta
                            name="twitter:image"
                            content="https://employes.nl/static/meta-boekhouder.jpg"
                        />
                    </Helmet>

                    <header className="header padding-xxl">
                        <div className="backdrop"></div>

                        <div className="container-md">
                            <div className="grid yg center text-center align-middle">
                                <div className="col-12 header-text">
                                    <h2 className="eyebrow">
                                        Overzicht Adviseurs
                                    </h2>
                                    <h1>Vind een HR- of financieel adviseur</h1>
                                    <p className="streamer center margin-m-bottom">
                                        Ben je op zoek naar financieel advies of heb je hulp nodig bij je HR activiteiten?
                                        Wij werken samen met een aantal betrouwbare organisaties. Je vind er vast één die bij jouw organisatie past!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </header>

                    <section>
                        <div className="container-md padding-m">
                            <div className="grid center">
                                <div className="col-8">
                                    {edges.map((partner, idx) => (
                                        <div className="ais-Hits-item ">
                                            <Link
                                                key={idx}
                                                to={partner.node.slug}
                                                className="card"
                                            >
                                                <div className="grid">
                                                    <div className="col-3">
                                                        <div className="logo-container">
                                                            <GatsbyImage
                                                                image={
                                                                    partner.node
                                                                        .logo
                                                                        .gatsbyImageData
                                                                }
                                                            />
                                                        </div>
                                                        <div className="btn secondary hide-mobile hide-tablet">
                                                            Lees meer&nbsp;→
                                                        </div>
                                                    </div>
                                                    <div className="col-9">
                                                        <h4 className="no-mar">
                                                            {partner.node.name}
                                                        </h4>
                                                        <div className="margin-xxs-bottom">
                                                            <img
                                                                src={marker}
                                                                className="marker"
                                                                alt="Marker icon"
                                                            />
                                                            <span className="addresss">
                                                                <span className="hide-mobile">
                                                                    {
                                                                        partner
                                                                            .node
                                                                            .locations[0]
                                                                            .address
                                                                    }
                                                                    ,{' '}
                                                                    {
                                                                        partner
                                                                            .node
                                                                            .locations[0]
                                                                            .postcode
                                                                    }
                                                                </span>{' '}
                                                                {
                                                                    partner.node
                                                                        .locations[0]
                                                                        .city
                                                                }
                                                            </span>{' '}
                                                            <br />
                                                        </div>
                                                        <p className="short-description margin-xxs-bottom">
                                                            {
                                                                partner.node
                                                                    .shortDescription
                                                                    .shortDescription
                                                            }
                                                        </p>
                                                        {partner.node
                                                            .accountingPackages
                                                            ?.length && (
                                                            <span className="no-hover">
                                                                <strong>
                                                                    Boekhoudpakket:
                                                                    &nbsp;
                                                                </strong>
                                                                {partner.node.accountingPackages?.join(
                                                                    ', '
                                                                )}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <section className="cta">
                                <div className="container-sm">
                                    <div className="cta-row padding-l margin-l-top margin-xl-bottom light">
                                        <div className="container-md">
                                            <div className="grid center text-center">
                                                <div className="col-10">
                                                    <h6 className="eyebrow">
                                                        Employes adviseurs
                                                    </h6>
                                                    <h2>Ook samenwerken met Employes?</h2>
                                                    <p className="cta-text">
                                                        Heb je interesse in een
                                                        samenwerking met Employes?
                                                        Vul je gegevens in via
                                                        onderstaand formulier.
                                                        Wij kijken of er een match is,
                                                        en wie weet sta jij
                                                        binnenkort ook op onze website.
                                                    </p>
                                                </div>
                                                <div className="col-6 margin-m-top sign-up-container">
                                                    <div className="sign-up">
                                                        <a
                                                            href="https://robemployesnl.typeform.com/to/KMWsoJ"
                                                            target="_blank"
                                                            className="link blue"
                                                            rel="nofollow"
                                                        >
                                                            Ja, wil graag samenwerken met Employes
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                </main>
            </Layout>
        )
    }
}

export default AccountantsPage

export const partnersQuery = graphql`
    query {
        allContentfulPartners(filter: { node_locale: { eq: "nl" } }) {
            edges {
                node {
                    name
                    slug
                    shortDescription {
                        shortDescription
                    }
                    locations {
                        address
                        postcode
                        city
                    }
                    logo {
                        gatsbyImageData
                    }
                    accountingPackages
                }
            }
        }
    }
`
